import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Carga perezosa de los componentes
const Principal = React.lazy(() => import('./components/dashboard/Dashboard'));
const SitioWeb = React.lazy(() => import('./components/forms/frmWeboficial'));
const Redes = React.lazy(() => import('./components/forms/frmRedes'));
const NotFound = () => <div>404 - Página no encontrada</div>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router
  future={{
    v7_startTransition: true, // Activa la bandera v7_startTransition para evitar la advertencia
  }}
>

    <Suspense fallback={<div>Cargando...</div>}>
      <Routes>
        {/* Ruta para /sisfestibuffet */}
        <Route path="/sisfestibuffet/*" element={<Principal />} />

        {/* Ruta para /redes */}
        <Route path="/redes/*" element={<Redes />} />

        {/* Ruta para la URL base sin prefijo */}
        <Route path="/" element={<SitioWeb />} />

        {/* Ruta para manejar rutas no encontradas */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </Router>
);